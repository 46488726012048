<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap v-if="!reusecomponent">
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Authors
            <v-spacer></v-spacer>
            <add-button
              permission="library-create"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : '',save_flag=true
              "
            >
              New Author
            </add-button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">
                  <v-icon color="warning" small>phone</v-icon>
                  {{ item.mobile_number || (item.phone_number ||  " N/A")}}
                </td>
                <td class="text-xs-left">
                  <v-icon color="warning" small>flag</v-icon>

                  {{ item.nationality || "N?A"}}
                </td>
                <td class="text-xs-left">
                  <v-icon small color="primary">location_on</v-icon>
                  {{ item.address || "N/A" }}
                </td>
                <td class="text-xs-left">
                  <v-icon small color="primary">email</v-icon>
                  {{ item.email || "N/A" }}
                </td>
                <td class="text-right">
                  <edit-button
                    permission="library-update"
                    @agree="form.edit(item), (save_flag = false)"
                  />
                  <delete-button
                    permission="library-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-form
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
          >
            <v-text-field
              type="search"
              autocomplete="off"
              label="Author Name*"
              required
              class="pa-0"
              outlined
              dense
              v-model="form.name"
              name="name"
              :error-messages="form.errors.get('name')"
              :rules="[(v) => !!v || 'Name is required']"
            />
            <v-text-field
              autocomplete="off"
              label="Email"
              required
              class="pa-0"
              outlined
              dense
              v-model="form.email"
              :rules="emailRule"
              :error-messages="form.errors.get('email')"
            />
            <v-select
              autocomplete="off"
              :items="nationality"
              label="Nationality"
              outlined
              dense
              v-model="form.nationality"
              class="pa-0"
            ></v-select>
            <v-text-field
              autocomplete="off"
              label="Address"
              required
              class="pa-0"
              outlined
              dense
              v-model="form.address"
            />
            <v-text-field
              autocomplete="off"
              label="Phone Number"
              required
              class="pa-0"
              outlined
              dense
              type="number"
              v-model="form.phone_number"
            />
            <v-text-field
              autocomplete="off"
              label="Mobile Number"
              required
              class="pa-0"
              outlined
              dense
              type="number"
              v-model="form.mobile_number"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="form.reset(), (save_flag = true), reset()"
                >Close</v-btn
              >
              <v-btn color="success" text @click="validate" v-if="save_flag"
                >Save</v-btn
              >
              <v-btn color="success" text @click="validate" v-else
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import nationalityData from "@/assets/json/nationality.json";

export default {
  mixins: [Mixins],
  props: {
    reusecomponent: {
      default: false,
    },
  },
  data: () => ({
    save_flag: true,
    valid: true,
    lazy: false,
    nationalityData,
    nationality: [],
    form: new Form(
      {
        dialog: false,
        name: "",
        nationality: "",
        address: "",
        phone_number: "",
        mobile_number: "",
        email: "",
        age: "",
      },
      "library/api/author"
    ),
    emailRule: [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"],

    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Contact",
        align: "left",
        value: "mobile_number",
        sortable: false,
      },
      {
        text: "Nationality",
        align: "left",
        value: "nationality",
        sortable: false,
      },
      { text: "Address", align: "left", value: "address", sortable: false },
      { text: "Email", align: "left", value: "email", sortable: false },
      { text: "Action", align: "right", sortable: false },
    ],
  }),
  watch: {
    pagination: function () {
      this.get();
    },
  },
  mounted() {
    this.getNationality();
    this.form.dialog = this.reusecomponent;
  },
  methods: {
    get(params) {
      let extraparams =
        "&book_id=" +
        (this.$route.query.book_id != undefined
          ? this.$route.query.book_id
          : "");
      let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    getNationality() {
      this.nationalityData.forEach((obj) => {
        this.nationality.push(obj.nationality);
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.form.store().then(() => {
          if (this.reusecomponent) {
            this.getAuthors();
          }
        });
      }
    },
    getAuthors() {
      this.$emit("getAuthors");
      this.form.dialog = false;
    },
    reset() {
      if (this.reusecomponent) {
        this.$emit("reset");
      }
      this.form.dialog = false;
    },
  },
};
</script>